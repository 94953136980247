<template>
  <div>
    <Toolbar>
      <template v-slot:title>
        {{ $t("pages.community.title") }}
      </template>
      <template v-slot:subNavigation>
        <ToolbarNavigation />
      </template>
    </Toolbar>

    <section class="min-h-screen-toolbar py-6 md:py-10">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import ToolbarNavigation from "@/components/community/ToolbarNavigation";

export default {
  name: "Operations",
  components: {
    ToolbarNavigation,
  },
};
</script>
